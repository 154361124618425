@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import "./app/shared/styles/custom-bootstrap";
@import "./app/shared/styles/bootstrap/bootstrap.scss";
@import "./app/shared/styles/custom-material.scss";
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

// global
html,
body {
  // scrollbar - firefox
  scroll-behavior: smooth;
  scrollbar-color: $primary rgba(0, 0, 0, 0.02);
  scrollbar-width: thin;
  overscroll-behavior-y: contain;
}

// scrollbar - other browsers
::-webkit-scrollbar{
  width: 8px;
}
::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.02);
}
::-webkit-scrollbar-thumb {
  background-color: $primary;
  border-left: 1px solid white;
  border-right: 1px solid white;
}

// all browsers
.dropdown-menu, .mat-select-panel{
  scrollbar-color: $primary #fff;
  scrollbar-width: thin;
  
  &::-webkit-scrollbar-track{
    background-color: #fff;
  }
}

p,
a,
div, :not(.mat-form-field-infix, text, tspan),
span {
  @media (min-width: 1600px) {
    font-size: 18px;
  }
}

a {
  text-decoration: none !important;
}

button:focus, div:focus {
  outline: none !important;
}

.logo {
  img {
    width: 113px;
  }
}

img:focus {
  outline: none !important;
}

input {
  line-height: normal !important;
}

// grid gutters
@media (max-width: 992px) {
  .row:not(.no-gutters) {
    margin-right: -($grid-gutter-width / 2.5) / 2;
    margin-left: -($grid-gutter-width / 2.5) / 2;
  }

  .row:not(.no-gutters)>.col,
  .row:not(.no-gutters)>[class*="col-"] {
    padding-right: ($grid-gutter-width / 2.5) / 2;
    padding-left: ($grid-gutter-width / 2.5) / 2;
  }
}

.curs-ptr {
  cursor: pointer;
}

// animation (icon)
@keyframes icon-dance {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(-45deg);
  }

  50% {
    transform: rotate(0deg);
  }

  75% {
    transform: rotate(45deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.icon-anim-container:hover {
  .icon-anim-img {
    animation: icon-dance 3s infinite;
  }
}

// scale on hover
.scale-container .scale-obj,
.scale-me {
  transition: transform 0.3s linear;
}

.scale-container:hover .scale-obj,
.scale-me:hover {
  transform: scale(1.05);
}

// validation error messages
.mat-form-field-subscript-wrapper{
  z-index: 2;
  mat-error{
    color: white !important;
    background: #f44336;
    padding: 5px 10px;
    border-radius: 6px;
    width: fit-content;
    margin-left: auto;
    max-width: 170px;
    font-size: 12px;
  }
}

// main loading
.main-loading{
  width: fit-content;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  h3{
    font-size: 32px;
  }
}