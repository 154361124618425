// spacers
$spacer: 1rem !default;
$spacers: (0: 0,
  1: $spacer / 4,
  2: $spacer / 2,
  3: $spacer,
  3_5: $spacer * 1.25,
  4: $spacer * 1.5,
  4_5: $spacer * 2,
  5: $spacer * 3,
  6: $spacer * 5,
  7: $spacer * 9,
  10: 0.625rem) !default;

$enable-negative-margins: true;

// colors
$gray-100: #f8f9fa !default;
$gray-600: #6c757d !default;
$gray-900: #212529 !default;
$green: #198754 !default;
$cyan: #008DDC !default;
$yellow: #ffc107 !default;
$red: #dc3545 !default;

$primary: #008B6D !default;
$light-primary: #E8F8F5 !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-900 !default;

$theme-colors: ("primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
  "light-primary": $light-primary) !default;

// grid breakpoints
$grid-breakpoints: (xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1500px) !default;

// container breakpoints
$container-max-widths: (sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
  xxxl:1440px) !default;

$grid-gutter-width: 2.5rem !default;

// typography
$line-height-base: 16*1.4;
