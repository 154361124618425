// Custom Theming for Angular Material
@import "~@angular/material/theming";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@include mat-core();

$main-color: #008B6D;

$my-teal: (50: $main-color,
  100: $main-color,
  200: $main-color,
  300: $main-color,
  400: $main-color,
  500: $main-color,
  600: $main-color,
  700: $main-color,
  800: $main-color,
  900: $main-color,
  A100: $main-color,
  A200: $main-color,
  A400: $main-color,
  A700: $main-color,
  contrast: (50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white,
  ),
);

$dontWasteMoney-primary: mat-palette($my-teal);
$dontWasteMoney-accent: mat-palette($my-teal, 600);
$dontWasteMoney-warn: mat-palette($mat-red);

$dontWasteMoney-theme: mat-light-theme((color: (primary: $dontWasteMoney-primary,
      accent: $dontWasteMoney-accent,
      warn: $dontWasteMoney-warn,
    ),
  ));
@include angular-material-theme($dontWasteMoney-theme);

@media(max-width:1600px) {
  $custom-typography: mat-typography-config($font-family: '"Inter", sans-serif',
      $headline: mat-typography-level(24px, 32px, 400),
      $body-1: mat-typography-level(16px, 20px, 400));
  @include angular-material-typography($custom-typography);
}

@media(min-width:1600px) {
  $custom-typography: mat-typography-config($font-family: '"Inter", sans-serif',
      $headline: mat-typography-level(24px, 32px, 400),
      $body-1: mat-typography-level(18px, 22px, 400));
  @include angular-material-typography($custom-typography);
}

button.mat-button.mat-primary,
button.mat-icon-button.mat-primary,
button.mat-stroked-button.mat-primary,
button.mat-raised-button.mat-primary,
button.mat-flat-button.mat-primary {
  border: 1px solid $main-color;
  min-width:auto;
}

button.mat-button,
button.mat-icon-button,
button.mat-stroked-button,
button.mat-raised-button,
button.mat-flat-button {
  border-radius: 0.5rem;
  height: 40px;
  transition: background 0.3s ease-in, color 0.2s ease-in;

  @media (max-width: 768px) {
    height: 44px;
  }
}

// button hover
.mat-primary.mat-flat-button.mat-button-disabled {
  color: #fff;
  background: $main-color;
  cursor: not-allowed;
}

.mat-primary.mat-stroked-button:hover {
  color: #fff;
  background: $main-color;
}

.mat-primary.mat-flat-button:hover {
  color: #fff;
  background: darken($main-color, 10%);
}

// form fields
.mat-form-field-infix {
  padding: 0.5069rem 0 !important;
  border-top: 0.3rem solid transparent !important;
}

.mat-select-arrow-wrapper {
  vertical-align: bottom !important;
}